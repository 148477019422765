import AOS from 'aos';

jQuery( document ).ready(function($) {
  
  // Animations
  AOS.init({
    duration: 1000,
    once: true,
  });

  const toggleMenu = () => {
    $('.header-navigation').slideToggle()
  }

  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    toggleMenu()
  })

});